import {getOutlet} from 'reconnect.js';
import {req} from './Utils/ApiUtils';
import Config from '../data.json';
import {navigate as nav} from 'gatsby';

const LoadingOutlet = getOutlet('loading');
const UserOutlet = getOutlet('user');

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function setLoading(loading) {
  setTimeout(() => {
    LoadingOutlet.update(loading);
  }, 0);
}

function navigate(nextRoute, options = {loading: false}) {
  const currRoute = '/' + window.location.href.split('/').slice(3).join('/');
  if (currRoute !== nextRoute) {
    if (options?.loading) {
      LoadingOutlet.update(true);
      if (typeof options.loading === 'number') {
        setTimeout(() => {
          LoadingOutlet.update(false);
        }, options.loading);
      }
    }
    nav(nextRoute);
  } else {
    console.log('path not changed, ignore...');
  }
}

function renderCustomSection() {
  return null;
}

async function getUploadUrlFromFile(file, options = {}) {
  const fileKey = file.name.split('.')[0];
  const fileType = file.type;
  const {acl = 'public-read'} = options;
  return await req(
    `${Config.uploadHost}/storage/presigned/url?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        acl,
        'Content-Type': fileType,
        key: `${fileKey}`,
      },
    },
  );
}

async function syncProviderOrderToJstorage(dateStr) {
  try {
    return await req(
      `${Config.apiHost}/order?token=${UserOutlet.getValue().token}`,
      {
        method: 'POST',
        data: {
          choice_date: dateStr,
        },
      },
    );
  } catch (err) {
    throw err;
  }
}

async function refreshYahooProductList() {
  try {
    const start = new Date();
    const end = new Date(start.getTime() - 365 * 24 * 3600 * 1000);

    return await req(
      `${Config.apiHost}/yahoo/product/list?token=${
        UserOutlet.getValue().token
      }`,
      {
        method: 'POST',
        data: {
          ProductDateStart: start.toISOString().split('T')[0],
          ProductDateEnd: end.toISOString().split('T')[0],
          QueryType: 'ALL',
        },
      },
    );
  } catch (err) {
    throw err;
  }
}

export {
  delay,
  setLoading,
  navigate,
  renderCustomSection,
  getUploadUrlFromFile,
  syncProviderOrderToJstorage,
  refreshYahooProductList,
};
