import {getOutlet} from 'reconnect.js';

function saveToStorage({clear = false} = {}) {
  if (clear) {
    getOutlet('editing').update([]);
    getOutlet('beforeEditing').update([]);
  }

  window.localStorage.setItem(
    'editing',
    JSON.stringify(getOutlet('editing').getValue()),
  );
  window.localStorage.setItem(
    'beforeEditing',
    JSON.stringify(getOutlet('beforeEditing').getValue()),
  );
}

function loadFromStorage() {
  try {
    getOutlet('editing').update(
      JSON.parse(window.localStorage.getItem('editing')) || [],
    );
    getOutlet('beforeEditing').update(
      JSON.parse(window.localStorage.getItem('beforeEditing')) || [],
    );
  } catch (ex) {
    console.warn('loadFromStorage', ex);
  }
}

function findRecordIdx(record) {
  return getOutlet('editing')
    .getValue()
    .findIndex((r) => r.provider === record.provider && r.id === record.id);
}

function getEditingRecord(record) {
  if (!record) {
    return null;
  }
  const idx = findRecordIdx(record);
  if (idx < 0) {
    return null;
  }
  return getOutlet('editing').getValue()[idx] || null;
}

function isRecordChanged(record) {
  const idx = findRecordIdx(record);
  if (idx > -1) {
    const record = getOutlet('editing').getValue()[idx];
    const beforeRecord = getOutlet('beforeEditing').getValue()[idx];
    return JSON.stringify(record) !== JSON.stringify(beforeRecord);
  }
  return false;
}

function undoChangedRecord(record) {
  const idx = findRecordIdx(record);
  if (idx > -1) {
    const nextRecords = [...getOutlet('editing').getValue()];
    const beforeRecord = getOutlet('beforeEditing').getValue()[idx];
    nextRecords[idx] = beforeRecord;
    getOutlet('editing').update(nextRecords);
  }
}

function addRecord(record) {
  const idx = findRecordIdx(record);
  if (idx === -1) {
    // we might add '__idx' into record in listview, so we have to remove it
    record = {...record};
    delete record.__idx;

    const nextRecords = [...getOutlet('editing').getValue(), record];
    getOutlet('editing').update(nextRecords);

    getOutlet('beforeEditing').update([
      ...getOutlet('beforeEditing').getValue(),
      record,
    ]);
  }
}

function getGroupRecords(internalRecord) {
  if (internalRecord.provider !== 'internal') {
    throw new Error('only internal record allowed');
  }

  const begin = findRecordIdx(internalRecord);
  if (begin > -1) {
    let records = getOutlet('editing').getValue();
    let result = [records[begin]];
    for (let idx = begin + 1; idx < records.length; idx++) {
      if (records[idx].provider === 'internal') {
        break;
      }
      result.push(records[idx]);
    }
    return result;
  }
  return [];
}

function removeRecord(record) {
  const idx = findRecordIdx(record);
  if (idx > -1) {
    const nextRecords = [...getOutlet('editing').getValue()];
    nextRecords.splice(idx, 1);
    getOutlet('editing').update(nextRecords);

    const nextBeforeRecords = [...getOutlet('beforeEditing').getValue()];
    nextBeforeRecords.splice(idx, 1);
    getOutlet('beforeEditing').update(nextBeforeRecords);
  }
}

const updateRecordFn = (record) => (nextRecord) => {
  const idx = findRecordIdx(record);

  if (idx > -1) {
    const nextRecords = [...getOutlet('editing').getValue()];
    nextRecords[idx] = nextRecord;
    getOutlet('editing').update(nextRecords);
  }
};

export {
  findRecordIdx,
  addRecord,
  removeRecord,
  updateRecordFn,
  isRecordChanged,
  getGroupRecords,
  undoChangedRecord,
  saveToStorage,
  loadFromStorage,
  getEditingRecord,
};
