import React from 'react';
import styled from 'styled-components';
import {Layout, Menu, Drawer, Button, message} from 'antd';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import {withLoginRequired} from './LoginRequired';
import * as AppActions from '../AppActions';
import imgEditProduct from '../images/menu/edit-product.png';
import imgUploadProduct from '../images/menu/upload-product.png';
import imgInternalProduct from '../images/menu/internal-product.png';
import imgMomocenter from '../images/menu/momocenter.png';
import imgMomomall from '../images/menu/momomall.png';
import imgPlatformCost from '../images/menu/platform-cost.png';
import imgRakuten from '../images/menu/rakuten.png';
import imgShopee from '../images/menu/shoppee.png';
import imgYahoo from '../images/menu/yahoo.jpeg';
import imgYahoomall from '../images/menu/yahoomall.jpeg';
import imgOrder from '../images/menu/order.png';
import imgMainProduct from '../images/menu/main-product.png';
import imgHome from '../images/menu/home.png';

const SiteInfo = {
  icon: '/images/yk-logo_vertical.png',
};

const Routes = [
  {name: '首頁', icon: imgHome, path: '/admin'},
  {name: '編輯商品', icon: imgEditProduct, path: '/admin/editing'},
  // {name: '商品上傳', icon: imgUploadProduct, path: '/admin/upload'},
  {name: '平台後扣設定', icon: imgPlatformCost, path: '/admin/site-config'},
  {name: '訂單總覽', icon: imgOrder, path: '/admin/order'},
  // {name: '編輯訂單號碼', icon: imgOrder, path: '/admin/edit-order'},
  {name: '商品主檔', icon: imgMainProduct, path: '/admin/main-products'},
  {
    name: '內部產品',
    icon: imgInternalProduct,
    path: 'internal-product-non-path',
    subroutes: [
      {
        name: '商城',
        path: '/admin/products/mall',
      },
      {
        name: '購物中心',
        path: '/admin/products/center',
      },
    ],
  },
  // {
  //   name: 'SHOPEE產品',
  //   path: '/admin/shopee/products',
  //   icon: imgShopee,
  // },
  // {
  //   name: 'YAHOO產品',
  //   path: '/admin/yahoo/products',
  //   icon: imgYahoo,
  // },
  // {
  //   name: 'YAHOO-MALL產品',
  //   path: '/admin/yahoo-mall/categories',
  //   icon: imgYahoomall,
  // },
  // {
  //   // name: 'MOMO-CENTER產品', // 加圖片太長了不顯示
  //   path: '/admin/momo-center/products',
  //   icon: imgMomocenter,
  // },
  // {
  //   // name: 'MOMO-MALL產品', // 加圖片太長了不顯示
  //   path: '/admin/momo-mall/products',
  //   icon: imgMomomall,
  // },
  // {
  //   // name: 'RAKUTEN產品', // 加圖片太長了不顯示
  //   path: '/admin/rakuten/products',
  //   icon: imgRakuten,
  // },
];

function AdminLayout(props) {
  const {children, location} = props;
  const [actions] = useOutlet('actions');
  const [siteConfig, setSiteConfig] = useOutlet('site-config');
  const [dimension] = useOutlet('dimension');
  // const mobile = !dimension.rwd || dimension.rwd === 'mobile';
  const [collpase, setCollapse] = React.useState(false);
  const [records] = useOutlet('editing');
  const [activeRoute, setActiveRoute] = React.useState(null);

  React.useEffect(() => {
    const _fetchData = async () => {
      actions.setLoading(true);
      try {
        let result = await actions.fetchDocuments(
          'platform_config',
          {},
          ['-created'],
          {
            offset: 0,
            limit: 1,
          },
        );

        if (result.results.length < 1) {
          throw 'please config platform_config collection first!';
        }
        setSiteConfig(result.results[0]);
      } catch (err) {
        message.error('請聯絡工程團隊設置預設平台設定', 5);
      } finally {
        actions.setLoading(false);
      }
    };

    _fetchData();
  }, []);

  const getMenuProps = (path) => {
    let selected = false;

    if (path === location.pathname) {
      selected = true;
    } else if (
      location.pathname === '/admin/yahoo-mall/products' &&
      path === '/admin/yahoo-mall/categories'
    ) {
      selected = true;
    }

    return {
      selected,
      onClick: () => {
        if (path.indexOf('momo-mall')) {
          // momo-mall will require a search keyword, so don't show spinner
          AppActions.navigate(path);
        } else {
          AppActions.navigate(path, {loading: true});
        }
      },
    };
  };

  const siderStyle = {
    overflow: 'auto',
    height: '100vh',
    position: 'fixed',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    transition: 200,
    left: 0,
  };

  const findRouteByKey = function (key) {
    let _found = null;
    for (let route of Routes) {
      if (route.path === key) {
        _found = route;
      } else if (route.subroutes) {
        _found = route.subroutes.find((s) => s.path === key);
      }
      if (_found) {
        return _found;
      }
    }

    return _found;
  };

  const _onRouteItemClick = React.useCallback(
    async ({item, key, keyPath, domEvent}) => {
      if (key === 'close') {
      } else if (key === 'logout') {
        await actions.logout();
        AppActions.navigate('/');
      } else {
        const _triggerredItem = findRouteByKey(key);
        _triggerredItem &&
          AppActions.navigate(_triggerredItem.path, {loading: 800});
      }
      setCollapse(false);
    },
    [Routes],
  );

  React.useEffect(() => {
    const _triggerredItem = findRouteByKey(location.pathname);
    if (_triggerredItem) {
      setActiveRoute(_triggerredItem.key);
    }
  }, [location.pathname]);

  return (
    <AdminLayoutWrapper>
      <Layout>
        <div className="drawer-btn" style={{background: '#fff', padding: 20}}>
          <Button type="primary" size="large" onClick={() => setCollapse(true)}>
            選單
          </Button>
        </div>

        <Layout
          style={{
            backgroundColor: 'white',
          }}>
          {children}
        </Layout>
        <Drawer
          title={
            <AppHeaderWrapper style={{width: 200}}>
              <figure>
                <img src={SiteInfo.icon} alt="site icon" />
              </figure>
            </AppHeaderWrapper>
          }
          placement="left"
          onClose={() => {
            setCollapse(false);
          }}
          visible={collpase}>
          <Menu
            defaultSelectedKeys={[activeRoute]}
            mode="inline"
            theme="light"
            onClick={_onRouteItemClick}>
            {Routes.map(({name, icon, path, subroutes}, idx) =>
              subroutes ? (
                <Menu.SubMenu
                  icon={icon ? <MenuIcon src={icon} /> : null}
                  key={path}
                  title={name}>
                  {subroutes.map((subroute, idx) => (
                    <Menu.Item
                      title={subroute.title}
                      key={subroute.path}
                      {...getMenuProps(subroute.path)}>
                      {subroute.icon && <MenuIcon src={subroute.icon} />}
                      {subroute.name}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ) : (
                <Menu.Item key={path} {...getMenuProps(path)}>
                  {icon && <MenuIcon src={icon} />}
                  {path === '/admin/editing'
                    ? `${name}(${records.length})`
                    : name}
                </Menu.Item>
              ),
            )}

            <Menu.Item key={'logout'} style={{marginBottom: 60}}>
              登出
            </Menu.Item>
          </Menu>
          {/* </Layout.Sider> */}
        </Drawer>
      </Layout>
    </AdminLayoutWrapper>
  );
}

const MenuIcon = styled.img`
  max-width: 180px;
  max-height: 30px;
  margin-right: 10px;
`;

const AppHeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  & > figure {
    padding: 10px;
    margin: 10px 0px;
    & > img {
      width: 100%;
      object-fit: contain;
    }
  }
  & > .content {
    padding: 8px;
    & p {
      padding: 0;
      margin: 0;
      color: var(--theme-color);
      font-size: 18px;
    }
  }
  margin-bottom: 15px;
`;

const AdminLayoutWrapper = styled.div`
  --theme-color: #132d4d;
  --theme-color-light: #3777c7;
`;
export default withLoginRequired(AdminLayout);
