import {getOutlet} from 'reconnect.js';
import Config from '../../data.json';

let _refreshToken = null;
const MAXIMUM_RETRY_COUNT = 3;

const UserOutlet = getOutlet('user');
async function req(
  url,
  {method = 'GET', headers = {}, data, ...restOptions} = {},
  retryCount = 0,
) {
  const _hasTokenInUrl = url.indexOf('token=') !== -1;
  const resp = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    ...(method !== 'GET' && data ? {body: JSON.stringify(data)} : {}),
    ...restOptions,
  });

  if (200 <= resp.status && resp.status < 400) {
    try {
      return await resp.json();
    } catch (ex) {
      // bypass
    }
    return null;
  }

  const err = {status: resp.status};

  try {
    err.response = await resp.json();
  } catch (ex) {
    // bypass
  }

  // handle for access token expired, refresh token and fetch again
  if (err.status === 403 || err.status === 400 || err.status === 410) {
    if (
      err.response.error === 'permission_denied' &&
      err.response.detail === 'Signature has expired' &&
      _hasTokenInUrl &&
      _refreshToken &&
      retryCount < MAXIMUM_RETRY_COUNT
    ) {
      try {
        let refreshTokenResp = await req(
          `${Config.authHost}/jwt/access?refresh_token=${_refreshToken}`,
        );

        let _token = refreshTokenResp.token;
        if (_hasTokenInUrl) {
          url = url.replace(/(\?|&)(token=)[^\&]+/, `$1$2${_token}`);
          UserOutlet.update({...UserOutlet.getValue(), token: _token});
        }

        let resp = await req(
          url,
          {method, headers, data, ...restOptions},
          retryCount + 1,
        );

        return resp;
      } catch (err) {
        throw err;
      }
    }
  }

  throw err;
}

// TODO: show warn or alert based on env configurations
function onApiError(ex) {
  console.warn(ex);
  const errMsg = `API Fail: ${JSON.stringify(ex, null, 2)}`;
  console.warn(errMsg);
  alert(errMsg);
}

function setRefreshToken(token) {
  _refreshToken = token;
}

export {req, onApiError, setRefreshToken};
