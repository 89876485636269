import {getOutlet} from 'reconnect.js';
import jwtDecode from 'jwt-decode';
import {navigate} from 'gatsby';
import {req, setRefreshToken} from '../Utils/ApiUtils';
import Config from '../../data.json';
import * as EditingUtil from '../Utils/EditRecordUtil';

const UserOutlet = getOutlet('user');
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

async function login({username, password}) {
  const resp = await req(`${Config.apiHost}/user/login`, {
    method: 'POST',
    data: {username, password},
  });

  const decoded = jwtDecode(resp.token);

  UserOutlet.update({username: decoded.sub, ...resp, ...decoded});
  if (typeof window !== undefined) {
    window.localStorage.setItem('token', resp.refresh);
  }

  navigate('/admin/products/mall');

  EditingUtil.loadFromStorage();
}

async function autoLogin() {
  if (typeof window !== undefined) {
    const token = window.localStorage.getItem('token');
    if (token) {
      const resp = await req(
        `${Config.authHost}/jwt/access?refresh_token=${token}`,
      );

      setRefreshToken(token);
      const decoded = jwtDecode(resp.token);

      UserOutlet.update({username: decoded.sub, ...resp, ...decoded});

      if (window.location.pathname === '/') {
        navigate('/admin/products/mall');
      }

      EditingUtil.loadFromStorage();
      return true;
    }
  }
  return false;
}

async function logout() {
  await delay(600);
  UserOutlet.update(null);
  if (typeof window !== undefined) {
    window.localStorage.removeItem('token');
  }
}

export {login, logout, autoLogin};
