// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-admin-home-index-js": () => import("./../../../src/Templates/AdminHome/index.js" /* webpackChunkName: "component---src-templates-admin-home-index-js" */),
  "component---src-templates-admin-product-list-index-js": () => import("./../../../src/Templates/AdminProductList/index.js" /* webpackChunkName: "component---src-templates-admin-product-list-index-js" */),
  "component---src-templates-editing-index-js": () => import("./../../../src/Templates/Editing/index.js" /* webpackChunkName: "component---src-templates-editing-index-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/Templates/Landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-main-product-list-index-js": () => import("./../../../src/Templates/MainProductList/index.js" /* webpackChunkName: "component---src-templates-main-product-list-index-js" */),
  "component---src-templates-momo-center-product-list-index-js": () => import("./../../../src/Templates/MomoCenterProductList/index.js" /* webpackChunkName: "component---src-templates-momo-center-product-list-index-js" */),
  "component---src-templates-momo-mall-product-list-index-js": () => import("./../../../src/Templates/MomoMallProductList/index.js" /* webpackChunkName: "component---src-templates-momo-mall-product-list-index-js" */),
  "component---src-templates-order-index-js": () => import("./../../../src/Templates/Order/index.js" /* webpackChunkName: "component---src-templates-order-index-js" */),
  "component---src-templates-rakuten-category-list-index-js": () => import("./../../../src/Templates/RakutenCategoryList/index.js" /* webpackChunkName: "component---src-templates-rakuten-category-list-index-js" */),
  "component---src-templates-rakuten-product-list-index-js": () => import("./../../../src/Templates/RakutenProductList/index.js" /* webpackChunkName: "component---src-templates-rakuten-product-list-index-js" */),
  "component---src-templates-shopee-product-list-index-js": () => import("./../../../src/Templates/ShopeeProductList/index.js" /* webpackChunkName: "component---src-templates-shopee-product-list-index-js" */),
  "component---src-templates-site-config-index-js": () => import("./../../../src/Templates/SiteConfig/index.js" /* webpackChunkName: "component---src-templates-site-config-index-js" */),
  "component---src-templates-yahoo-mall-category-list-index-js": () => import("./../../../src/Templates/YahooMallCategoryList/index.js" /* webpackChunkName: "component---src-templates-yahoo-mall-category-list-index-js" */),
  "component---src-templates-yahoo-mall-product-list-index-js": () => import("./../../../src/Templates/YahooMallProductList/index.js" /* webpackChunkName: "component---src-templates-yahoo-mall-product-list-index-js" */),
  "component---src-templates-yahoo-product-list-index-js": () => import("./../../../src/Templates/YahooProductList/index.js" /* webpackChunkName: "component---src-templates-yahoo-product-list-index-js" */)
}

